@import "../../styles/variables.scss";

#privacyPolicy #data {
  padding-right: 50px;
}

#privacyPolicy #nav .ant-anchor-link a {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-size: smaller;
}

#privacyPolicyTitle {
  margin-top: 10px;
}

#privacyPolicy Typography {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

#privacyPolicy .break {
  height: 8px;
}

#privacyPolicy ul {
  list-style: none;
}

#privacyPolicy li::before {
  content: "\2022";
  font-weight: bold;
  font-size: medium;
  display: inline-block;
  width: 1.5em;
  margin-left: -1em;
}
