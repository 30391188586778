@import "../../styles/variables.scss";

.wellView .ant-card-head {
  font-size: 20px;
}

.removeDescriptionHeaderPadding > .ant-descriptions-header {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
