@import "../../../styles/variables.scss";

.table-row-light {
  background-color: #ffffff;
}
.table-row-light td {
  background: none !important;
  background-color: #ffffff !important;
  opacity: 1;
}

.table-row-dark {
  background: none !important;
  background-color: #e5f4ff !important;
  opacity: 1;
}

.table-row-dark td {
  background: none !important;
  background-color: #e5f4ff !important;
  opacity: 1;
}
