@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --chart-1: 240, 100%, 25%;
    --chart-2: 210, 100%, 35%;
    --chart-3: 210, 100%, 60%;
    --chart-4: 210, 100%, 75%;
    --chart-5: 210, 100%, 92%;
    --chart-6: 210, 100%, 20%;
    --chart-7: 200, 100%, 45%;
    --chart-8: 210, 100%, 65%;
    --chart-9: 210, 100%, 85%;
    --chart-10: 210, 100%, 95%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;

    --chart-1: 240, 100%, 25%;
    --chart-2: 210, 100%, 35%;
    --chart-3: 210, 100%, 60%;
    --chart-4: 210, 100%, 75%;
    --chart-5: 210, 100%, 92%;
    --chart-6: 210, 100%, 20%;
    --chart-7: 200, 100%, 45%;
    --chart-8: 210, 100%, 65%;
    --chart-9: 210, 100%, 85%;
    --chart-10: 210, 100%, 95%;

    /* DEFAULT: '#2A669F',
  50: '#E4F7F8',
  100: '#CCEEF2',
  200: '#9CD7E5',
  300: '#6CB9D8',
  400: '#3B94CB',
  500: '#2A669F',
  600: '#234B83',
  700: '#1B3366',
  800: '#14204A',
  900: '#0C102E' */
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

.recharts-surface {
  overflow: visible !important;
}
