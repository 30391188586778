@import "../../../styles/variables.scss";

.table-row-light {
  background-color: #ffffff;
}

.table-row-light td {
  background: none !important;
  background-color: #ffffff !important;
  opacity: 1;
}

.table-row-dark {
  background: none !important;
  background-color: #e5f4ff !important;
  opacity: 1;
}

.table-row-dark td {
  background: none !important;
  background-color: #e5f4ff !important;
  opacity: 1;
}

.media {
  @media print {
    @page {
      size: landscape;

      @bottom-right {
        content: "Page " counter(page);
      }
    }

    .ant card {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }

    .ant-table-row {
      page-break-inside: avoid;
    }

    .ant-card {
      page-break-inside: auto;
    }

    .ant-card {
      page-break-after: always;
    }
  }

  // @media print {
  //     .page-break {
  //         margin-top: 1rem;
  //         display: block;
  //         page-break-before: auto;
  //     }
  // }
}
