@import "../../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css?family=Damion");

.cluster-marker {
  color: #fff;
  background: $primary;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}
