#profileDropdown {
  width: 300px;
  box-shadow: none;
}

#profileDropdown .ant-card-head {
  padding: 0px 0px 10px 0;
}

#profileDropdown .ant-card-body {
  padding: 0px 0px 0px 0;
}
