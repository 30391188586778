@import "./styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Damion&family=Playwrite+US+Trad:wght@100..400&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-btn-primary:not(:disabled) {
  background-color: $primary !important;
}

.ant-btn-link:enabled {
  color: $primary !important;
}

.ant-pagination-item-active {
  border-color: $primary !important;
}

.ant-pagination-item-active a {
  color: $primary !important;
}

.ant-btn-default:not(:disabled):hover {
  color: $primary !important;
  border-color: $primary !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: $primary !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: $primary !important;
  border-color: $primary !important;
}

.ant-switch.ant-switch-checked {
  background: $primary !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;
}

.ant-tabs-tab:hover {
  color: $primary !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: $primary !important;
  pointer-events: none;
}

.googleMarkerLabelPrimary {
  color: white !important;
  font-size: 16px !important;
  font-family: customFont, Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  margin-bottom: 50px;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000 !important;
}

.ant-card {
  box-shadow: 0px 0px 5px 0px #bcbbbb !important;
  border-radius: 8px !important;
  margin: 5px !important;
}

.ant-descriptions {
  box-shadow: 0px 0px 5px 0px #bcbbbb !important;
  border-radius: 8px !important;
  margin: 5px !important;
}

.removeMargin {
  margin: 0 !important;
}

.removeBoxShadow {
  box-shadow: none !important;
}

.cursiveFont {
  font-family: Da;
  font-family: "Damion", sans-serif;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  * {
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }

  .ant-table-summary {
    page-break-inside: auto !important;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always !important;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .ant-table-cell {
    padding: 2px !important;
    font-size: 0.8em !important;
  }

  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 2px 5px 2px 5px !important;
    font-size: 0.8em !important;
  }

  .ant-card-body {
    padding: 8px !important;
  }

  .remove-border-and-padding-on-print {
    margin: 0 !important;
    box-shadow: none !important;
    border: none !important;
  }

  .print-margin {
    margin: 10px !important;
    padding: 10px !important;
  }
}

@page {
  size: A3 landscape;
  margin: 1in;

  @bottom-right {
    content: "Page " counter(page);
  }
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.removeShadow {
  box-shadow:
    1px 0 0 0 #d6d6d692,
    0 1px 0 0 #d6d6d692,
    1px 1px 0 0 #d6d6d692,
    1px 0 0 0 #d6d6d692 inset,
    0 1px 0 0 #d6d6d692 inset !important;
}

.customScrollBarStyle .ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
}

.customScrollBarStyle .ant-table-body::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

.customScrollBarStyle .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #000000 !important;
  border: 4px solid $primary !important;
}

.customSelectScrollBar .rc-virtual-list-scrollbar-thumb {
  background: blue !important;
}

.customScrollBarStyle .ant-card-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
}

.customScrollBarStyle .ant-card-body::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

.customScrollBarStyle .ant-card-body::-webkit-scrollbar-thumb {
  background-color: #000000 !important;
  border: 4px solid $primary !important;
}

.customSelectScrollBar .rc-virtual-list-scrollbar-thumb {
  background: blue !important;
}

.customSelectScrollBar .rc-virtual-list-scrollbar-thumb {
  background-color: #000000 !important;
  border: 3px solid $primary !important;
  border-radius: 0 !important;
  visibility: visible !important;
  width: 4px !important;
}

.customSelectScrollBar .rc-virtual-list-scrollbar-thumb:hidden {
  display: block;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table {
  margin-inline: 0px !important;
  margin-block: 0px !important;
}
