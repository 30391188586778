@import "../../styles/variables.scss";

#globalHeader {
  display: flex;
  align-items: stretch;
  background-color: hsl(0, 0%, 100%);
  position: sticky;
  z-index: 999;
  top: -10px;
  box-shadow: 0px 0px 5px 0px #bcbbbb;
}

#globalHeaderLogo {
  color: $primary;
}

#leftHeaderMenu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 64px;
}

#leftHeaderMenu .navigationLink {
  height: 100%;
  border-radius: 0;
  color: $primary;
  border: none;
}

#leftHeaderMenu .navigationLink:hover {
  color: rgb(66, 66, 230);
}

#leftHeaderMenu .navigationLink.active {
  border: 0;
  border-bottom: 1px solid $primary;
  margin-bottom: 1px;
  margin-top: -4px;
  scale: 1.1;
  color: $primary;
}

#rightHeaderMenu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  //margin-left: 64px;
}
