// @import "../../styles/variables.scss";
@import "../../../styles/variables.scss";

.ant-card-grid.selected {
  border: 2px solid #52c41a;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.ant-card-grid.selected:hover {
  box-shadow: 0 0 8px rgba(82, 196, 26, 0.5);
}
