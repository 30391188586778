@import "../../styles/variables.scss";

#termsConditions #data {
  padding-right: 50px;
}

#termsConditions #nav .ant-anchor-link a {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-size: smaller;
}

#termsConditionsTitle {
  margin-top: 10px;
}

#termsConditions Typography {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

#termsConditions .break {
  height: 8px;
}

#termsConditions ul {
  list-style: none;
}

#termsConditions li::before {
  content: "\2022";
  font-weight: bold;
  font-size: medium;
  display: inline-block;
  width: 1.5em;
  margin-left: -1em;
}
