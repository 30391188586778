@import "../../../styles/variables.scss";

.headerColorBlue {
  .ant-table-thead .ant-table-cell {
    background-color: #e5f4ff !important;
  }
}

.headerColorWhite {
  .ant-table-thead .ant-table-cell {
    background-color: white !important;
  }
}
